import React, { useEffect, useRef, useState } from "react";

import * as S from "./style";

const SearchBar = (props: Props) => {
  const { value, onChange, onEnter, placeholder } = props;

  const [isQuery, setIsQuery] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsQuery(!!value.length);
  }, [value]);

  const focus = () => inputRef.current?.focus();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleOnClear = () => {
    onChange("");
    focus();
  };

  const handleOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter && onEnter(value);
    }
  };

  return (
    <S.Wrapper>
      <S.Input
        ref={inputRef}
        value={value}
        onChange={handleOnChange}
        onKeyPress={handleOnEnter}
        placeholder={placeholder}
      />
      {isQuery ? (
        <S.ClearButton onClick={handleOnClear} />
      ) : (
        <S.SearchButton onClick={focus} />
      )}
    </S.Wrapper>
  );
};

interface Props {
  value: string;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
  placeholder?: string;
}

export default SearchBar;
