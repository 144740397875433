import styled from "styled-components";
import { Box } from "@sqymagma/elements";

export const MiniWidgetWrapper = styled(Box)`
  padding: ${(p) => p.theme.spacing.xs};
  color: ${(p) => p.color};
  cursor: ${(p) => (p.onClick ? "pointer" : "default")};
  display: ${(p) => (p.$large ? "flex" : "block")};
`;

export const IconWrapper = styled(Box)`
  margin-left: auto;
`;
