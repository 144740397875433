import styled from "styled-components";

const IconWrapper = styled.div<{ $color?: string }>`
  display: contents;

  & svg {
    fill: ${(p) => p.$color && (p.theme.colors[p.$color] || p.$color)};
  }
`;

export { IconWrapper };
