import React from "react";
import { Text, Box } from "@sqymagma/elements";
import Tilt from "react-parallax-tilt";

import { beautifyDate } from "@helpers";
import { Taxonomy } from "@types";

import * as S from "./style";

const Item = (props: Taxonomy) => {
  const { title, date, description, url, image } = props;

  const handleClick = () => window.open(url, "_blank");

  const beautyDate = beautifyDate(date);

  return (
    <Tilt tiltMaxAngleY={5} tiltMaxAngleX={5}>
      <S.Item onClick={handleClick}>
        <S.Background $image={image} />
        <S.Overlay />
        <Box zIndex={0}>
          <Box>
            <Text textStyle="subtitle02" color="text01">
              {beautyDate}
            </Text>
          </Box>
          <Box mt="xxxs">
            <Text textStyle="headingm" color="text01">
              {title}
            </Text>
          </Box>
          <Box mt="xxs">
            <Text textStyle="body" color="text01">
              {description}
            </Text>
          </Box>
        </Box>
      </S.Item>
    </Tilt>
  );
};

export default Item;
