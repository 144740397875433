import styled from "styled-components";
import { Box } from "@sqymagma/elements";

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  & svg {
    fill: ${(p) => p.theme.colors.text01};
  }
`;

export { Wrapper };
