import styled, { Keyframes, keyframes } from "styled-components";

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } 
`;

const spinDefault = keyframes`
  0% {
    stroke-dashoffset: 95;
    transform: scaleY(1);
  }
  49.99% {
    stroke-dashoffset: 0;
    transform: scaleY(1);
  }
  50% {
    stroke-dashoffset: 0;
    transform: scaleY(-1) rotate(25deg);
  }
  100% {
    stroke-dashoffset: 95;
    transform: scaleY(-1) rotate(-32deg);
  }
`;

const spinSmall = keyframes`
  0% {
    stroke-dashoffset: 47.5;
    transform: scaleY(1);
  }
  49.99% {
    stroke-dashoffset: 0;
    transform: scaleY(1);
  }
  50% {
    stroke-dashoffset: 0;
    transform: scaleY(-1) rotate(25deg);
  }
  100% {
    stroke-dashoffset: 47.5;
    transform: scaleY(-1) rotate(-32deg);
  }
`;

const Spinner = styled.svg`
  display: block;
  margin: 0 auto;
  animation: ${rotation} 2s linear infinite;
  will-change: transform;
`;

const Circle = styled.circle<{
  animation: Keyframes;
  dashOffset: number;
  light?: boolean;
}>`
  stroke: ${(p) =>
    p.light ? p.theme.colors.overlayLight : p.theme.colors.overlay01};
  stroke-dasharray: ${(p) => p.dashOffset};
  stroke-dashoffset: ${(p) => p.dashOffset};
  stroke-linecap: round;
  animation: ${(p) => p.animation} 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite;
  transform: translateZ(0);
  transform-origin: center;
  will-change: stroke-dashoffset;
`;

export { Spinner, Circle, spinDefault, spinSmall };
