import styled from "styled-components";
import { theme } from "@sqymagma/theme";
import { motion } from "framer-motion";

const Wrapper = styled(motion.div)`
  ${theme("textStyle.body")};
  border-radius: ${theme("radii.s")};
  padding: ${theme("spacing.xxs")};
  color: ${theme("colors.text02")};
  background-color: ${theme("colors.secondaryBackground")};
  margin-top: ${theme("spacing.xxxs")};
  text-align: center;
  box-shadow: ${(p) => p.theme.shadows.small};
  box-sizing: border-box;
`;

export { Wrapper };
