import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const ScrollToTop = (props: RouteComponentProps) => {
  const { history } = props;

  useEffect(() => {
    const unregister = history.listen(() => {
      window.scrollTo({ top: 0 });
    });

    return () => unregister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
