import styled from "styled-components";
import { theme } from "@sqymagma/theme";
import { motion } from "framer-motion";

const Note = styled(motion.div)`
  position: absolute;
  top: ${(p) => `-${p.theme.spacing.xxxs}`};
  left: 50%;
  border-radius: ${(p) => p.theme.radii.s};
  transform: translateX(-50%) translateY(-100%);
  padding: ${(p) => p.theme.spacing.xxxs};
  color: ${(p) => p.theme.colors.dark};
  background-color: ${(p) => p.theme.colors.warning};
  box-shadow: ${(p) => p.theme.shadows.small};
  z-index: 1;
  ${theme("textStyle.tiny")};
  pointer-events: none;
  min-width: 150px;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-top: 6px solid ${(p) => p.theme.colors.warning};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

export { Note };
