import { useEffect, useState } from "react";

const getMql = () => {
  if (typeof window === "undefined") {
    return;
  }
  return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
};

const getSystemTheme = (matches: boolean): "light" | "dark" =>
  matches ? "dark" : "light";

const getDefaultTheme = () => {
  const mql = getMql();
  if (mql) {
    return getSystemTheme(mql.matches);
  }
  return "light";
};

const useSystemTheme = () => {
  const defaultTheme = getDefaultTheme();
  const [systemTheme, setSystemTheme] = useState(defaultTheme);

  useEffect(() => {
    const mql = getMql();
    const mqlListener = (e: MediaQueryListEvent) =>
      setSystemTheme(getSystemTheme(e.matches));
    if (mql) {
      setSystemTheme(getSystemTheme(mql.matches));
      mql.addEventListener("change", mqlListener);
    }
    return () => mql && mql.removeEventListener("change", mqlListener);
  }, []);

  return systemTheme;
};

export { useSystemTheme };
